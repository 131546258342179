import React from 'react';
import Loadable from 'react-loadable';
import Spinner from "../components/spinner";

import { isLoggedIn } from "../components/auth-service";
// Defaulting "english" as locale fallback
import Layout from "../layouts/en";

const getLocation = () => (typeof window !== "undefined") && window.location;

const LoadableComponent = Loadable.Map({
  loader: {
    Login: () => import('./login'),
    Dashboard: () => import('./dashboard'),
  },
  loading() {
    return <Spinner />;
  },
  render(loaded, props) {
    let Login = loaded.Login.default;
    let Dashboard = loaded.Dashboard.default;
    if (isLoggedIn()) {
      return (
        <Layout {...Object.assign({}, props, { location: getLocation() })}><Dashboard {...props} /></Layout>
      );
    } else {
      return (
        <Layout {...Object.assign({}, props, { location: getLocation() })}><Login {...props} /></Layout>
      );
    }
  }
});

class IndexPage extends React.Component {
  render() {
    return <LoadableComponent />;
  }
}

export default IndexPage;

