import React, { useState } from "react";
import EventDispatcher from "./event-dispatcher";

import "./loading.css";

const Spinner = () => {
  const [visible, setVisible] = useState(true);
  EventDispatcher.subscribe("alifa.spinner.visible", (visible) => setVisible(visible));
  return (
    <div className="loading-page-wrapper" style={{display: (!!visible ? "block" : "none")}}>
      <div className="loading-page loading-page-animation">&nbsp;</div>
      <div className="loading-page2 loading-page-animation">&nbsp;</div>
      <div className="loading-page3 loading-page-animation">&nbsp;</div>
    </div>
  );
}

export default Spinner;

